// Component Block
// ========================================================
.styled-list {
  margin: 10px 0;
}

// Component Elements
// ========================================================
.styled-list-header {
  font-weight: bold;
}

.styled-list-header-container {
  padding: 12px 6px;
}

.styled-list-item {
  padding: 12px 6px;

  &:not(:last-child) {
    border-bottom: 1px solid darken(#edf1f5, 20%);
  }
}

.styled-list-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
