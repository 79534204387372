.office-overview-header {
  background: #efefef;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem!important;
}

.office-overview-btn {
  background: #fff;
  font-size: .8em;
  cursor: pointer;
  border: 0;
  padding: .5em .8em;
  border-radius: 4px;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
  transition: 0.5s all;

  &:hover {
    background: #f1f1f1;
  }
}
