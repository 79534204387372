.login-screen {
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100vh;
}

.login-screen-panel {
  background: #fff;
  width: 340px;
  // @include desktop() {
  //   width: 340px;
  // }
}

.panel-body {
  overflow: hidden;

}
