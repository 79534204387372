.styled-toast {
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: auto;
}

.styled-toast-disabled {
  display: none;
}
