.desk-list-item {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.desk-list-item-restricted {
  padding: 10px;
  margin-top: 20px;
  background: #f3f3f3;
  border: 1px solid darken(#f3f3f3, 10%);
}
