.navbar {
  @include mobile() {
    padding-bottom: 20px;
  }
}

.header-branded {
  min-height: 80px;
  display: flex;
  align-items: center;
}

.navbar-brand {
  background: #fff;
  min-height: 80px;
  padding: 0 0.8em;
  display: flex;
  align-items: center;
}

.navbar-menu {
  @include mobile() {
    padding-top: 20px;
    justify-content: center!important;
  }
}

// Nav Links
.header-action-item {
  font-size: 14px;
  background: #fff;
  border-radius: 3px;
  padding: 0.4em 0.8em;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 36px;
  transition: 0.5s all;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);

  &:hover {
    transition: 0.5s all;
    opacity: 0.7;
  }
}

.header-links {
  align-items: center;
}

.header-user-profile {
  font-size: 0.8em;
  background: #fff;
  border-radius: 5px;
  padding: 5px 10px;
}

.header-logout-btn {
  color: #354a55;
  cursor: pointer;
  display: inline-block;
}


.navbar .navbar-section {
  @include mobile() {
    flex: 100%;
    justify-content: space-between;
  }
}
