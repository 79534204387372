.list {
  list-style: none;
  margin: 0;
}

.item {
  &:nth-child(odd) {
    background: #edf1f5;
  }
}

.details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  width: 100%;

  p {
    font-size: 0.9em;
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.type {
  text-transform: capitalize;
}

.editor {
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #ccc;
  margin: 0.5em 0;
  padding: 0.5em 0;
}
