.heading-bar {
  text-align: center;
  background: #fff;
  padding: 4em 0;
}

.heading-bar-title {
  font-size: 2em;
  font-weight: 300;
  letter-spacing: 2px;
  line-height: 0.8em;
}

.heading-bar-subtitle {
  font-size: 1em;
  color: $gray-color;
}
