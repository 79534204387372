.popup-box {
  border: 1px solid #ccc;
  background: #fff;
  width: auto!important;
  text-align: center;
  padding: 10px 20px!important;
}

.popup-box-title {
  font-size: 1em;
}
