.deleted-item {
  opacity: 0.2;
}

.is-hidden {
  display: none;
}

.fixed-image {
  max-width: 100%;
}

.has-no-bullets {
  list-style: none;
  margin: 0;
}
